.pulse-circle {
  width: 72px;
  height: 72px;
}

.pulse-circle > * {
  position: relative;
}

.pulse-circle:after,
.pulse-circle:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  pointer-events: none;
}

.pulse-circle::before {
  opacity: 0.3;
  animation: pulse-ring-1 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulse-circle::after {
  opacity: 0.2;
  animation: pulse-ring-2 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-ring-1 {
  0% {
    transform: translate(-50%, -50%);
  }

  80%,
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

@keyframes pulse-ring-2 {
  0% {
    transform: translate(-50%, -50%);
  }

  80%,
  100% {
    transform: translate(-50%, -50%) scale(3.2);
    opacity: 0;
  }
}
