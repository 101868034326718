* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 112.5%;
}

body {
  letter-spacing: -0.02em;
  overflow-y: auto;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -3%;
}

:root {
  --#{$variable-prefix}font-serif: #{inspect($font-family-serif)};
}

.container {
  max-width: calc(1280px + (#{$spacer} * 2));
  padding-left: $spacer * 1;
  padding-right: $spacer * 1;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;

  @include media-breakpoint-up(lg) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

strong {
  color: $headings-color;
}

.highlight {
  color: $headings-color;
  position: relative;
  font-weight: normal;
  padding-top: 0.22em;
  padding-bottom: 0.23em;
  background-image: linear-gradient(
    90.45deg,
    rgba(#ff7223, 0.3) 0%,
    rgba(#ffde67, 0.3) 100%
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

body {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#0c161a, 0.4);
    z-index: 25;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
  }

  &.dropdown-overlay {
    &::before {
      opacity: 1;
    }
  }
}

.product-page {
  background-image: linear-gradient(
    180deg,
    #ffffff 53.08%,
    rgba(242, 243, 246, 0.75) 100%
  );
}
