.standardized-tests {
  position: relative;
  overflow: hidden;

  &.section {
    @include media-breakpoint-up(lg) {
      padding-top: 170px;
      padding-bottom: 170px;
    }
  }

  .highlight {
    color: $white;
  }

  @include media-breakpoint-down(lg) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &::before {
      background-image: linear-gradient(
        90deg,
        #192429 0%,
        rgba(16, 32, 41, 0.19) 79.98%
      );
    }

    &::after {
      background-image: linear-gradient(
        77.2deg,
        #7267ea 0%,
        rgba(40, 182, 238, 0.32) 64.27%
      );
      opacity: 0.2;
    }
  }
}

.standardized-tests-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @include media-breakpoint-down(lg) {
    object-position: 90%;
  }
}

.standardized-tests-title {
  color: $white;
  max-width: 600px;
  z-index: 2;
}

.standardized-tests-text {
  color: $white;
  font-size: 18px;
  z-index: 2;

  p:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}
