:root {
  --swiper-navigation-size: 70px !important;
  --swiper-theme-color: $black !important;
}

.swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination {
  bottom: -40px;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #c4c4c4;
    opacity: 1;
    transition: width 0.1s ease-in-out, border-radius 0.1s ease-in-out;
    will-change: width;

    &.swiper-pagination-bullet-active {
      width: 25px;
      border-radius: 5px;
    }
  }
}

.swiper {
  .swiper-button-next,
  .swiper-button-prev {
    width: var(--swiper-navigation-size);
    height: var(--swiper-navigation-size);
    background-color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.25);

    &::after {
      font-size: calc(var(--swiper-navigation-size) / 3);
    }
  }

  .swiper-button-next {
    padding-left: $spacer;
    justify-content: flex-start;
    right: calc(var(--swiper-navigation-size) / 2 * -1);
  }

  .swiper-button-prev {
    padding-right: $spacer;
    justify-content: flex-end;
    left: calc(var(--swiper-navigation-size) / 2 * -1);
  }
}
