.landing-logos {
  position: relative;
  overflow: hidden;
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;
  background-color: #e8f8ff;

  @include media-breakpoint-up(wide) {
    .container {
      max-width: 1480px;
    }
  }
}

.landing-logos-title {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: $spacer;

  @include media-breakpoint-up(lg) {
    text-align: left;
    margin-bottom: $spacer * 0.5;
  }
}

.landing-logos-list {
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -$spacer;

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
  }

  li {
    padding: $spacer;

    @include media-breakpoint-down(lg) {
      flex: 1 0 calc(100% / 3);
    }
  }

  li > img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 35px;
    object-fit: contain;
    object-position: center;

    @include media-breakpoint-up(lg) {
      max-width: 130px;
      max-height: 50px;
    }
  }
}
