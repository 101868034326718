$active-color: #21a56e;

.trustpilot {
  color: $headings-color;

  &.trustpilot-inline {
    display: flex;
    align-items: center;
  }
}

.trustpilot-stars {
  display: flex;
  align-items: center;
  height: 24px;

  .trustpilot-inline & {
    height: 18px;

    @include media-breakpoint-up(lg) {
    }
  }

  svg {
    height: 100%;
  }

  svg path:nth-child(2) {
    display: none;
    fill: $active-color;
  }

  &[data-rating='0.5'] {
    svg:nth-child(1) path:nth-child(2) {
      display: block;
    }
  }

  &[data-rating='1'] {
    svg:nth-child(1) path:nth-child(1) {
      fill: $active-color;
    }
  }

  &[data-rating='1.5'] {
    svg:nth-child(1) path:nth-child(1) {
      fill: $active-color;
    }

    svg:nth-child(2) path:nth-child(2) {
      display: block;
    }
  }

  &[data-rating='2'] {
    svg:nth-child(1) path:nth-child(1),
    svg:nth-child(2) path:nth-child(1) {
      fill: $active-color;
    }
  }

  &[data-rating='2.5'] {
    svg:nth-child(1) path:nth-child(1),
    svg:nth-child(2) path:nth-child(1) {
      fill: $active-color;
    }

    svg:nth-child(3) path:nth-child(2) {
      display: block;
    }
  }

  &[data-rating='3'] {
    svg:nth-child(1) path:nth-child(1),
    svg:nth-child(2) path:nth-child(1),
    svg:nth-child(3) path:nth-child(1) {
      fill: $active-color;
    }
  }

  &[data-rating='3.5'] {
    svg:nth-child(1) path:nth-child(1),
    svg:nth-child(2) path:nth-child(1),
    svg:nth-child(3) path:nth-child(1) {
      fill: $active-color;
    }

    svg:nth-child(4) path:nth-child(2) {
      display: block;
    }
  }

  &[data-rating='4'] {
    svg:nth-child(1) path:nth-child(1),
    svg:nth-child(2) path:nth-child(1),
    svg:nth-child(3) path:nth-child(1),
    svg:nth-child(4) path:nth-child(1) {
      fill: $active-color;
    }
  }

  &[data-rating='4.5'] {
    svg:nth-child(1) path:nth-child(1),
    svg:nth-child(2) path:nth-child(1),
    svg:nth-child(3) path:nth-child(1),
    svg:nth-child(4) path:nth-child(1) {
      fill: $active-color;
    }

    svg:nth-child(5) path:nth-child(2) {
      display: block;
    }
  }

  &[data-rating='5'] {
    svg:nth-child(1) path:nth-child(1),
    svg:nth-child(2) path:nth-child(1),
    svg:nth-child(3) path:nth-child(1),
    svg:nth-child(4) path:nth-child(1),
    svg:nth-child(5) path:nth-child(1) {
      fill: $active-color;
    }
  }
}

.trustpilot-logo {
  height: 24px;

  .trustpilot-inline & {
    height: 18px;

    @include media-breakpoint-up(lg) {
    }
  }
}

.trustpilot-label {
  font-size: 24px;
  line-height: 1;

  .trustpilot-inline & {
    font-size: 14px;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }
}

.trustpilot-text {
  font-size: 18px;

  .trustpilot-inline & {
    font-size: 12px;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }
}

.trustpilot-top {
  margin-bottom: 8px;

  .trustpilot-inline & {
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.trustpilot-top,
.trustpilot-bottom {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
