.blog-card {
  background-color: $white;
  box-shadow: 0 14.086px 25.3548px rgba(34, 36, 69, 0.06);
  height: 100%;
}

.blog-card-info {
  padding: 28px;
}

.blog-card-image-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 60%;
}

.blog-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.blog-card-meta {
  font-size: 16px;
  font-family: $font-family-sans-serif;
  margin-bottom: $spacer * 0.6;
  line-height: 1;

  a {
    color: $primary-dark;
  }
}

.blog-card-title {
  font-size: 22px;
  line-height: 1.4;

  a {
    display: block;
    color: currentColor;

    &:hover {
      color: $accent;
      text-decoration: none;
    }
  }
}

.blog-card-excerpt {
  font-size: 16px;
  line-height: 1.5;
}

.blog-card-more {
  font-size: 14px;
}
