.menu-industries {
}

.menu-industries-item-title {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 20px;

  span {
    margin-left: $spacer * 0.8;
  }

  &:not(:first-of-type) {
    margin-top: $spacer * 2;
  }
}

.menu-industries-sub-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  margin: $spacer 0 0;
  padding: 0 0 0 $spacer * 2;

  li {
    margin-bottom: $spacer;
  }

  a {
    position: relative;
    font-size: 18px;
    color: $headings-color;
    display: block;

    &:hover {
      text-decoration: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      width: 8px;
      height: 11px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='7.00734' height='1.33098' transform='matrix(-0.706861 -0.707352 0.706861 -0.707352 4.95312 5.89807)' fill='black'/%3E%3Crect width='7.15553' height='1.33098' transform='matrix(-0.706861 0.707352 -0.706861 -0.707352 6 5.07104)' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
}

.menu-industries-tests-list {
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:not(:last-child) {
    margin-bottom: $spacer * 1.5;
  }

  a {
    color: $primary-dark;

    strong {
      color: currentColor;
    }
  }
}
