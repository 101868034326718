.product-page-info-title {
  font-size: 28px;

  @include media-breakpoint-up(lg) {
    font-size: 32px;
  }
}

.product-page-top-section-row {
  @include media-breakpoint-up(lg) {
    --bs-gutter-x: 4rem;
  }
}

.product-page-info-quote {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.27;
  padding-top: 24px;
  border-top: 1px solid rgba(#95a5ad, 0.4);

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(#95a5ad, 0.4);
    line-height: 1.5;
  }

  img {
    width: 70px;
    height: 70px;

    @include media-breakpoint-up(lg) {
      width: 94px;
      height: 94px;
    }
  }
}

.product-page-info-quote-author {
  line-height: 1;
}
