.testimonials {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(322.87deg, #f2fdff 0%, #fffaf8 100%);
}

.testimonials-title,
.testimonials-subtitle {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.testimonials-subtitle {
  max-width: 590px;
}

.testimonials-image {
  margin-left: -$spacer;
  margin-right: -$spacer;
  width: calc(100% + #{$spacer * 2});
  height: 390px;
  object-fit: cover;
  object-position: 60%;

  @include media-breakpoint-up(md) {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.testimonials-image-wrapper {
  position: relative;

  @include media-breakpoint-down(lg) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -$spacer;
      width: calc(100% + #{$spacer * 2});
      height: 100%;
      background-image: linear-gradient(
        142.24deg,
        #ffffff 9.47%,
        rgba(255, 255, 255, 0.44) 100%
      );
      opacity: 0.55;
    }
  }
}

.testimonials-featured {
  position: relative;
}

.testimonials-caption {
  position: relative;
  background-color: $white;
  max-width: 840px;
  width: 100%;
  box-shadow: 16px 16px 80px rgba(26, 47, 53, 0.25);

  .col {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: $spacer * 3;
    margin-top: -200px;

    .col {
      width: auto;
    }

    .col:first-child {
      max-width: 295px;
      width: 100%;
    }
  }
}

.testimonials-caption-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: $spacer * 2 $spacer;

  @include media-breakpoint-up(lg) {
    padding: 36px;
  }
}

.testimonials-caption-description {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.3;
}

.testimonials-caption-right {
  color: $white;
  background-image: linear-gradient(135deg, $primary-dark 0%, #00b2ff 100%);
  height: 100%;
  padding: $spacer * 2 $spacer;

  @include media-breakpoint-up(lg) {
    padding: 50px;
  }
}

.testimonials-caption-quote {
  font-style: italic;
  line-height: 1.66666667;
}

.testimonials-caption-author {
  font-family: $font-family-sans-serif;
  font-weight: bold;
  line-height: 1.44444444;
}

.testimonials-caption-position {
  font-family: $font-family-sans-serif;
  line-height: 1.44444444;
}

.testimonials-quote-item {
}

.testimonials-quote-item-author {
  color: #7b878d;
  font-size: 20px;
  font-family: $font-family-sans-serif;
  margin-bottom: 0;

  strong {
    color: $headings-color;
  }
}

.testimonials-quote-item-platform {
  display: flex;
  align-items: center;
  color: #95a5ad;
  font-family: $font-family-sans-serif;

  span {
    font-size: 16px;
    font-weight: 700;
  }
}

.testimonials-quote-item-stars {
  display: flex;
  align-items: center;
  height: 18px;

  svg {
    display: block;
    height: 100%;
    margin-right: $spacer * 0.2;

    g {
      display: none;
    }
  }

  svg:last-child {
    margin-right: $spacer * 0.5;
  }

  &[data-rating='0.5'] {
    svg:nth-child(1) g {
      display: block;
    }
  }

  &[data-rating='1'] {
    svg:nth-child(1) path:first-child {
      fill: var(--tpx-rating-color);
    }
  }

  &[data-rating='1.5'] {
    svg:nth-child(1) path:first-child {
      fill: var(--tpx-rating-color);
    }

    svg:nth-child(2) g {
      display: block;
    }
  }

  &[data-rating='2'] {
    svg:nth-child(1) path:first-child,
    svg:nth-child(2) path:first-child {
      fill: var(--tpx-rating-color);
    }
  }

  &[data-rating='2.5'] {
    svg:nth-child(1) path:first-child,
    svg:nth-child(2) path:first-child {
      fill: var(--tpx-rating-color);
    }

    svg:nth-child(3) g {
      display: block;
    }
  }

  &[data-rating='3'] {
    svg:nth-child(1) path:first-child,
    svg:nth-child(2) path:first-child,
    svg:nth-child(3) path:first-child {
      fill: var(--tpx-rating-color);
    }
  }

  &[data-rating='3.5'] {
    svg:nth-child(1) path:first-child,
    svg:nth-child(2) path:first-child,
    svg:nth-child(3) path:first-child {
      fill: var(--tpx-rating-color);
    }

    svg:nth-child(4) g {
      display: block;
    }
  }

  &[data-rating='4'] {
    svg:nth-child(1) path:first-child,
    svg:nth-child(2) path:first-child,
    svg:nth-child(3) path:first-child,
    svg:nth-child(4) path:first-child {
      fill: var(--tpx-rating-color);
    }
  }

  &[data-rating='4.5'] {
    svg:nth-child(1) path:first-child,
    svg:nth-child(2) path:first-child,
    svg:nth-child(3) path:first-child,
    svg:nth-child(4) path:first-child {
      fill: var(--tpx-rating-color);
    }

    svg:nth-child(5) g {
      display: block;
    }
  }

  &[data-rating='5'] {
    svg:nth-child(1) path:first-child,
    svg:nth-child(2) path:first-child,
    svg:nth-child(3) path:first-child,
    svg:nth-child(4) path:first-child,
    svg:nth-child(5) path:first-child {
      fill: var(--tpx-rating-color);
    }
  }
}

.testimonials-quote-item-text {
  font-style: italic;
  line-height: 1.66666667;
}

.testimonials-trustpilot {
  position: relative;
  color: $headings-color;
  margin-top: -140px;
  margin-bottom: $spacer;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: $spacer * 2.4;
    left: $spacer * 2.4;
    margin-top: 0;
  }
}
