.dropdown {
  .dropdown-toggle {
    border: 0;

    &::after {
      height: 8px;
      width: 12px;
      border: 0;
      background-position: center 2px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 6'%3E%3Cpath fill='%23fff' d='M4.23 4.953 9.185 0l.941.941-4.953 4.954z'/%3E%3Cpath fill='%23fff' d='M5.057 6 0 .942.941 0l5.058 5.058z'/%3E%3C/svg%3E");
    }
  }

  .dropdown-menu {
    position: relative;
    border-radius: 5px;

    &::before {
      content: '';
      position: absolute;
      top: -15px;
      right: 25px;
      width: 30px;
      height: 30px;
      background-image: url("data:image/svg+xml, %3Csvg class='industries-dropdown-arrow' width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='14.2531' width='20.1568' height='20.1568' transform='rotate(-45 0 14.2531)' fill='white'/%3E%3C/svg%3E");
    }

    &.dropdown-menu-quantity {
      padding: 0;
      border-radius: 0;
      width: calc(100% - 1px);

      &::before {
        content: none;
      }
    }
  }
}
