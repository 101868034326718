.industries {
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: linear-gradient(322.87deg, #f2fdff 0%, #fffaf8 100%);

  @include media-breakpoint-up(lg) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

.industries-list {
  flex-flow: row wrap;

  .col {
    flex: 0 0 calc(100% / 2);

    @include media-breakpoint-up(md) {
      flex: 0 0 calc(100% / 3);
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 calc(100% / 5);
    }
  }
}

.industries-text {
  color: #6e8498;
  font-size: 18px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.industries-item {
  color: $primary-dark;
  display: block;
  text-align: center;

  &:hover {
    color: $accent;
    text-decoration: none;
  }
}

.industries-item-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
  transition: transform 0.25s ease-in-out;

  .industries-item:hover & {
    transform: translateY(-10px);
  }
}

.industries-item-title {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: $spacer * 0.3;
  transition: color 0.25s ease-in-out;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }

  .industries-item:hover & {
    text-decoration: underline;
  }
}

.industries-item-meta {
  color: #95a5ad;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1;
}
