@mixin header-transition($type) {
  transition: $type 0.25s ease-in-out;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10000;

  &.position-sticky {
    position: sticky;
  }

  &.position-relative {
    position: relative;
  }

  @include header-transition(background-color);

  @include media-breakpoint-up(wide) {
    .container {
      max-width: 1480px;
    }
  }

  .navbar-collapse {
    flex-grow: 0;
  }

  .navbar {
    padding-top: $spacer;
    padding-bottom: $spacer;

    @include header-transition(padding);

    .nav-link {
      font-size: 16px;
      font-weight: bold;

      &.show {
        color: $accent !important;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 6'%3E%3Cpath fill='%23f5351c' d='M4.23 4.953 9.185 0l.941.941-4.953 4.954z'/%3E%3Cpath fill='%23f5351c' d='M5.057 6 0 .942.941 0l5.058 5.058z'/%3E%3C/svg%3E") !important;
        }
      }

      &:hover {
        color: $brand;
        text-decoration: underline;

        &:hover::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 6'%3E%3Cpath fill='%23ff8c8c' d='M4.23 4.953 9.185 0l.941.941-4.953 4.954z'/%3E%3Cpath fill='%23ff8c8c' d='M5.057 6 0 .942.941 0l5.058 5.058z'/%3E%3C/svg%3E");
        }
      }
    }

    .navbar-toggler {
      border: 0;

      &:focus {
        box-shadow: none;
      }
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 35 25'%3E%3Cpath fill='%23fff' d='M0 2.206C0 .988.98 0 2.188 0h30.625C34.02 0 35 .988 35 2.206s-.98 2.206-2.188 2.206H2.189A2.197 2.197 0 0 1 0 2.206ZM0 12.5c0-1.218.98-2.206 2.188-2.206h30.625c1.208 0 2.187.988 2.187 2.206s-.98 2.206-2.188 2.206H2.189A2.197 2.197 0 0 1 0 12.5ZM0 22.794c0-1.218.98-2.206 2.188-2.206h30.625c1.208 0 2.187.988 2.187 2.206S34.02 25 32.812 25H2.189A2.197 2.197 0 0 1 0 22.794Z'/%3E%3C/svg%3E");
    }

    @include media-breakpoint-down(lg) {
      background-color: $primary;

      &.bg-white {
        background-color: $white;
      }

      .navbar-nav {
        margin-top: $spacer;
      }

      .nav-link {
        text-align: center;
        padding-top: $spacer * 0.5;
        padding-bottom: $spacer * 0.5;
      }
    }
  }

  .navbar-brand {
    display: block;
    padding: 0;
    height: 40px;

    @include media-breakpoint-up(lg) {
      height: 50px;
      margin-top: -$spacer;
    }

    > img {
      display: block;
      width: auto;
      height: 100%;

      @include media-breakpoint-down(lg) {
        width: 140px;
        height: auto;
      }
    }

    > img:last-child {
      display: none;
    }
  }

  &.header-sticky {
    background-color: rgba(#e6e8ed, 1);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 35 25'%3E%3Cpath fill='%23293845' d='M0 2.206C0 .988.98 0 2.188 0h30.625C34.02 0 35 .988 35 2.206s-.98 2.206-2.188 2.206H2.189A2.197 2.197 0 0 1 0 2.206ZM0 12.5c0-1.218.98-2.206 2.188-2.206h30.625c1.208 0 2.187.988 2.187 2.206s-.98 2.206-2.188 2.206H2.189A2.197 2.197 0 0 1 0 12.5ZM0 22.794c0-1.218.98-2.206 2.188-2.206h30.625c1.208 0 2.187.988 2.187 2.206S34.02 25 32.812 25H2.189A2.197 2.197 0 0 1 0 22.794Z'/%3E%3C/svg%3E");
    }

    .navbar-brand {
      img:first-child {
        display: none;
      }

      img:last-child {
        display: block;
      }
    }

    .btn.btn-login {
      color: $white;
      background-color: $headings-color;
    }

    .btn.btn-login:hover {
      color: $white;
      background-color: $accent;
    }

    .navbar {
      .nav-link {
        color: $headings-color;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 6'%3E%3Cpath fill='%23293845' d='M4.23 4.953 9.185 0l.941.941-4.953 4.954z'/%3E%3Cpath fill='%23293845' d='M5.057 6 0 .942.941 0l5.058 5.058z'/%3E%3C/svg%3E");
        }

        &:hover {
          color: $accent;
        }
      }

      @include media-breakpoint-down(xxl) {
        padding-top: 6px;
        padding-bottom: 6px;
      }

      @include media-breakpoint-down(lg) {
        background-color: transparent;
      }
    }
  }
}

.header-form {
  max-width: 435px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  pointer-events: none;

  @include header-transition(opacity);

  input {
    height: 50px;
  }

  .header-sticky & {
    opacity: 1;
    pointer-events: auto;
  }

  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.header-topbar {
  background-color: rgba(#0b2353, 0.3);

  @include header-transition(background-color);

  .header-sticky & {
    background-color: rgba(#cfd4de, 1);
  }
}

.header-lists {
  color: $white;
  font-family: $font-family-sans-serif;
  font-size: 12px;
  line-height: 1.1;

  @include header-transition(color);

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }

  a {
    @include header-transition(color);

    color: $white;

    &:hover {
      color: $brand;
      text-decoration: underline;
    }
  }

  .header-sticky & {
    color: $headings-color;

    a {
      color: $headings-color;

      &:hover {
        color: $accent;
      }
    }
  }
}

.header-cart {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-left: auto;
  margin-right: 12px;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
