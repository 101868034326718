.product-quantity {
}

.product-quantity-list {
  position: relative;
  overflow: hidden;
  padding: 3px;
}

.product-quantity-list-gradient {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 10px;
  display: block;
  width: 135px;
  height: calc(100% - 10px);
  z-index: 1;

  @include media-breakpoint-up(lg) {
    width: 200px;
  }
}

.product-quantity-title {
  color: $primary-dark;
  font-size: 18px;
  margin-bottom: 4px;
}

.product-quantity-subtitle {
  font-size: 14px;
  margin-bottom: 16px;

  strong {
    color: #293845;
    font-style: italic;
    font-weight: 400;
  }
}

.product-quantity-select {
  font-family: $font-family-sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  margin-bottom: 7px;
  line-height: 1;

  &:last-child {
    position: relative;
    z-index: 50;
    background-color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 100%;
    height: 3px;
    display: block;
    background-color: #fff;
    z-index: 10;
  }

  input {
    position: absolute;

    text-align: center;
    opacity: 0;
    pointer-events: none;
  }

  .product-quantity-select-wrapper {
    cursor: pointer;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid rgba(149, 165, 173, 0.4);
    box-shadow: 0 0 0 2px $white;
    padding: 15px 15px 15px 90px;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      padding: 12px 24px 12px 70px;
    }

    &::before {
      content: '';
      color: $primary-dark;
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid currentColor;

      .product-quantity-select-simple & {
        content: none;
      }

      @include media-breakpoint-up(lg) {
        left: 24px;
      }
    }

    &::after {
      content: '';
      color: $primary-dark;
      position: absolute;
      top: 50%;
      left: 19px;
      transform: translateY(-50%);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: currentColor;
      opacity: 0;

      @include media-breakpoint-up(lg) {
        left: 29px;
      }
    }
  }

  .product-quantity-select-wrapper:hover {
    border: 1px solid rgba(33, 73, 153, 0.4);
    box-shadow: 0 0 0 2px rgba(33, 73, 153, 0.4);

    &::after {
      color: transparent;
      opacity: 1;
      animation: radio-button-hovered 0.2s ease-in-out forwards;
    }
  }

  input:checked + .product-quantity-select-wrapper {
    border-color: $accent;
    box-shadow: 0 0 0 2px $accent;

    &::before,
    &::after {
      opacity: 1;
      color: $accent;
    }

    &::after {
      animation: radio-button-clicked 0.4s ease-in-out;
    }
  }
}

.product-quantity-select-label {
  position: relative;
  color: $primary-dark;
  font-size: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;

  @include media-breakpoint-up(lg) {
    font-size: 38px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
    max-width: 52px;
    text-align: left;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }
  }
}

.product-quantity-select-price {
  position: relative;
  font-size: 12px;
  margin-right: auto;
  padding-left: 12px;
  text-align: left;
  z-index: 1;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    padding-left: 24px;
  }
}

.product-quantity-select-price-value {
  display: block;
  color: $accent;
  font-weight: 800;
  margin-bottom: 2px;
}

.product-quantity-select-price-discount {
  font-size: 12px;
  text-decoration: line-through;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }
}

.product-quantity-select-discount {
  position: relative;
  z-index: 1;
  font-size: 12px;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}

.product-quantity-select-discount-value {
  color: $accent;
  font-weight: 800;
  margin-bottom: 2px;
  display: block;
}

.product-quantity-select-discount-save {
  font-size: 12px;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }
}

@keyframes radio-button-clicked {
  0% {
    box-shadow: 0 0 0 0 rgb(233 48 48 / 15%);
  }

  50% {
    box-shadow: 0 0 0 15px rgb(233 48 48 / 15%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(233 48 48 / 15%);
  }
}

@keyframes radio-button-hovered {
  0% {
    box-shadow: 0 0 0 0 rgba(#214999, 0.15);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(#214999, 0.15);
    background-color: rgba(#214999, 0.15);
  }
}
