.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: linear-gradient(161.81deg, #f2fdff 9.89%, #fffaf8 90.15%);
  overflow: auto;

  .nav-open & {
    display: block;
  }

  .nav-link {
    position: relative;
    color: $headings-color;
    font-size: 20px;

    &:hover {
      color: $accent;
    }

    &.dropdown-toggle {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        width: 8px;
        height: 11px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='7.00734' height='1.33098' transform='matrix(-0.706861 -0.707352 0.706861 -0.707352 4.95312 5.89807)' fill='black'/%3E%3Crect width='7.15553' height='1.33098' transform='matrix(-0.706861 0.707352 -0.706861 -0.707352 6 5.07104)' fill='black'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

.mobile-menu-header {
  position: sticky;
  top: 0;
  background-color: #f1f5f8;
  box-shadow: 0 1px 3px rgba(35, 18, 83, 0.15);
  justify-content: space-between;
  align-items: center;
  height: 90px;
  z-index: 10;

  .navbar-brand {
    margin-top: -0.3em !important;
  }

  .navbar-toggler {
    padding: 0;
  }

  .btn {
    &.btn-back {
      font-size: 16px;
      padding: $spacer * 0.4 $spacer * 0.8;
      color: $headings-color;
      border: 2px solid;

      svg {
        width: 8px;
        height: 11px;
      }
    }

    &.btn-back-search {
      padding-left: 0;
      padding-right: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.mobile-menu-content {
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 90px);
}

.mobile-menu-footer {
  display: flex;
  position: sticky;
  z-index: 100;
  bottom: 0;
  background-color: #f1f5f8;
  box-shadow: 0 1px 3px rgba(35, 18, 83, 0.15);
  justify-content: space-between;

  .btn {
    &.btn-back {
      padding: $spacer * 0.4 $spacer * 0.8;
      color: $headings-color;
      border: 2px solid;
      width: 100%;
      text-transform: uppercase;

      svg {
        width: 8px;
        height: 11px;
      }
    }

    &.btn-search {
      min-width: 200px;
    }
  }
}

.mobile-menu-quick-links {
  display: flex;
  justify-content: space-evenly;
  padding-left: $spacer * 2;
  padding-right: $spacer * 2;

  a {
    font-family: $font-family-sans-serif;
    color: $headings-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 78px;
    height: 78px;
    background-color: #dadde4;
    border-radius: 15px;
    line-height: 1.2;
    padding-left: 20px;
    padding-right: 20px;

    svg {
      width: 20px;
      height: 20px;
      margin-bottom: 6px;
    }
  }
}

.mobile-menu-buttons {
  .btn {
    font-size: 18px;
    font-family: $font-family-sans-serif;
    font-weight: bold;
    padding-top: $spacer * 0.6;
    padding-bottom: $spacer * 0.6;
    border-radius: 25px;
    height: 50px;

    &.btn-login {
      color: $white;
      background-color: $headings-color;
    }

    &.btn-search {
      color: $headings-color;
      border: 2px solid $headings-color;
    }
  }
}

.mobile-menu-socials {
  display: flex;
  justify-content: center;

  a {
    color: $accent;
    transition: $transition-color;

    &:hover {
      color: $brand;
    }

    svg {
      width: 38px;
      height: 38px;
    }
  }
}

.mobile-menu-search-cat {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: $spacer;
}

.mobile-menu-search-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;

  li {
    margin-bottom: $spacer;
  }

  a {
    position: relative;
    font-size: 18px;
    color: $headings-color;
    display: flex;
    justify-content: space-between;
    padding-left: $spacer * 1.5;

    svg {
      width: 14px;
      height: 14px;
    }

    .btn {
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &::before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.5;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 0C13.9706 0 18 4.02944 18 9C18 11.1248 17.2637 13.0776 16.0323 14.6172L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L14.6172 16.0323C13.0776 17.2637 11.1248 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0V0ZM9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2Z' fill='%231B2D35'/%3E%3C/svg%3E%0A");
    }
  }

  &.no-icon a {
    &::before {
      content: none;
    }
  }
}
