.stats {
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;

  margin-left: auto;

  @include media-breakpoint-down(lg) {
    margin-left: auto;
    margin-right: auto;
  }
}

.stats-item {
  flex: 0 0 50%;
  border-right: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
  height: 100%;
  padding-top: $spacer;
  padding-bottom: $spacer;
  padding-left: $spacer;
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }

  &:nth-child(2),
  &:nth-child(4) {
    border-right: 0;
    padding-left: $spacer * 3;
  }

  &:nth-child(3),
  &:nth-child(4) {
    border-bottom: 0;
  }
}

.stats-value {
  color: $accent;
  font-size: 36px;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: 48px;
  }
}

.stats-title {
  font-size: 14px;
  margin-bottom: 0;
}

.stats-title,
.stats-value {
}
