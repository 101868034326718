.cart-page {
  flex: 1;
  background-color: #f2f3f6;

  @include media-breakpoint-down(lg) {
    > .container > .row {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
}

.card-page-header {
  @include media-breakpoint-down(lg) {
    text-align: center;
  }

  h1 {
    font-size: 28px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  p {
    font-family: $font-family-sans-serif;
  }

  img {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}

.card-page-header-back {
  font-size: 18px;
  font-family: $font-family-sans-serif;

  &:hover {
    text-decoration: none;
  }

  span {
    border-bottom: 1px solid;
    margin-left: 8px;
  }
}

.cart-page-badges {
  margin-right: 8px;

  @include media-breakpoint-up(lg) {
    width: 476px;
    height: auto;
    margin-right: 0;
  }
}

.cart-page-content {
  font-family: $font-family-sans-serif;
  background-color: $white;

  @include media-breakpoint-up(lg) {
    box-shadow: 0 15px 25px rgba(26, 61, 132, 0.05);
  }
}

.cart-page-content-inner {
  padding: 60px;

  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
}

.cart-page-summary {
  color: #95a5ad;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  background-color: rgba(236, 250, 255, 0.35);
  width: 460px;
  height: 100%;
  padding: 60px;

  @include media-breakpoint-down(lg) {
    padding: 1rem;
    width: 100%;
  }

  hr {
    background-color: rgba(#95a5ad, 0.4);
    opacity: 1;
  }
}

.cart-page-summary-total-title {
  color: #95a5ad;
  text-transform: uppercase;
  font-size: 16px;
  font-family: $font-family-sans-serif;
  font-weight: 800;
  text-align: right;
  border-bottom: 1px solid rgba(#95a5ad, 0.4);
  padding-bottom: 11px;
}

.cart-page-summary-total-price {
  padding-top: 24px;
  padding-bottom: 3px;

  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }

  &.pt-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.cart-page-content-column-title {
  color: #95a5ad;
  text-transform: uppercase;
  font-size: 18px;
  font-family: $font-family-sans-serif;
  font-weight: 800;
  text-align: left;
  border-bottom: 1px solid rgba(#95a5ad, 0.4);
  padding-bottom: 4px;
  margin-top: -5px;
}

.card-page-product-list {
  position: relative;
  border-bottom: 1px solid rgba(#95a5ad, 0.4);

  @include media-breakpoint-down(lg) {
    border-bottom: none;
  }
}

.card-page-product-list-remove {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;

  &:focus {
    box-shadow: none;
  }

  @include media-breakpoint-down(lg) {
    left: auto;
    right: 10px;
    width: 25px;
    height: 25px;
  }

  svg {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.card-page-product-list-title {
  color: $primary-dark;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  padding-left: 10px;
}

.card-page-product-list-preview {
  width: 70px;
  height: auto;
}

.card-page-help {
  font-family: $font-family-sans-serif;
  margin-top: auto;
  color: #95a5ad;

  p {
    font-size: 16px;
    margin-bottom: 8px;
  }

  a {
    color: #95a5ad;
    text-decoration: none;
    border-bottom: 1px solid;
    line-height: 1;
    padding-bottom: 1px;

    &:hover {
      color: $accent;
      text-decoration: none;
    }
  }
}
