.checkout-page {
  flex: 1;
  background-color: #f2f3f6;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    > .container > .row {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  .btn.btn-gradient.btn-gradient-checkout {
    font-weight: 700;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      max-width: 350px;
    }

    svg {
      width: 8px;
      height: 14px;
      margin-left: 14px;
      margin-right: -0.5rem;
    }
  }
}

.checkout-page-back {
  flex: 1;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  font-family: $font-family-sans-serif;
  line-height: 1.2;

  span {
    text-transform: uppercase;
    border-bottom: 1px solid;
  }

  &:hover {
    text-decoration: none;
  }

  svg {
    margin-right: 12px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 16px;
    font-weight: 400;

    svg {
      width: 6px;
      height: 12px;
      margin-top: 4px;
      margin-right: 6px;
    }

    span {
      text-transform: none;
    }
  }
}

.checkout-page-logo {
  position: absolute;
  top: 14px;
  left: 0;
}

.checkout-page-header {
  @include media-breakpoint-down(lg) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 64px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 12px;
  }

  h1 {
    font-size: 28px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
      padding: 0;
    }
  }

  p {
    font-family: $font-family-sans-serif;

    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }
  }
}

.checkout-page-row {
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.checkout-page-form {
  position: relative;
  background-color: $white;
  box-shadow: 0 15px 25px rgba(26, 61, 132, 0.05);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 55%;
    padding: 184px 60px 60px;
  }
}

.checkout-page-form-logo {
  padding: 8px 1rem;
  background-color: $white;

  @include media-breakpoint-down(lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 74px;

    img {
      width: auto;
      height: 30px;
      margin-top: -8px;
    }
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: -160px;
    left: 0;
    padding: 0;
  }
}

.checkout-page-form-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 100%;
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    flex: 1;
  }
}

.checkout-page-form-inner {
  flex: 1;
  padding-bottom: 80px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 50px;
  }
}

.checkout-page-terms-checkbox {
  font-family: $font-family-sans-serif;
  display: flex;
  font-size: 18px;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    max-width: 500px;
  }

  label,
  input {
    cursor: pointer;
  }

  input {
    width: 25px;
    height: 25px;
    border: 2px solid $primary;
    border-radius: 0 !important;
  }

  label {
    flex: 1;
  }
}

.checkout-page-total {
  font-family: $font-family-sans-serif;
  padding: 1rem;

  @include media-breakpoint-up(lg) {
    width: 45%;
    padding: 184px 50px 50px;
  }

  hr {
    background-color: #95a5ad;
    opacity: 0.4;
  }
}

.checkout-page-total-toggle {
  color: #ffc89f;
  display: flex;
  align-items: center;
  background-color: #1e438d;
  margin: -14px -1rem 0;
  padding: 0.9rem 1rem;
  transition: color 0.15s ease-in-out;

  .checkout-page-total-toggle-cart {
    margin-right: 12px;
  }

  .checkout-page-total-toggle-title {
    font-size: 16px;
    font-weight: 700;
  }

  .checkout-page-total-toggle-arrow {
    margin-left: 8px;
  }

  .checkout-page-total-toggle-price {
    font-size: 16px;
    font-weight: 700;
    margin-left: auto;
  }

  &[data-checkout-total-toggle='false'] {
    color: $white;
    background-color: #1e438d;

    .checkout-page-total-toggle-arrow {
      transform: rotate(180deg);
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.checkout-page-total-inner {
  max-width: 495px;

  @include media-breakpoint-down(lg) {
    &[data-checkout-total-inner='false'] {
      display: none;
    }
  }
}

.checkout-page-total-item {
  font-family: $font-family-sans-serif;
  display: flex;
  align-items: center;
  line-height: 1.2;
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  img {
    width: 70px;
    height: auto;
    margin-right: 15px;

    @include media-breakpoint-up(lg) {
      width: 122px;
    }
  }
}

.checkout-page-total-item-inner {
  flex: 1;
}

.checkout-page-total-item-title {
  display: block;
  color: $primary-dark;
  font-size: 16px;
  font-weight: 700;
  max-width: 165px;
  margin-bottom: 8px;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

.checkout-page-total-item-quantity {
  font-size: 18px;

  @include media-breakpoint-down(lg) {
    font-size: 14px;

    strong {
      font-weight: 400;
    }
  }
}

.checkout-page-total-item-discount {
  font-size: 18px;
  font-weight: 800;
  color: $accent;

  @include media-breakpoint-down(lg) {
    font-size: 14px;
    font-weight: 400;
  }
}

.checkout-page-total-item-prices {
  font-size: 16px;
  text-align: right;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }

  p:first-child {
    color: $primary-dark;
    font-weight: 600;
  }

  p:last-child {
    font-size: 14px;
    text-decoration: line-through;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }
}

.checkout-form-footer-sign,
.checkout-page-form-footer {
  @include media-breakpoint-down(lg) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.checkout-form-footer-sign {
  margin-top: auto;
}

.checkout-page-form-login-text {
  font-size: 20px;
  margin-bottom: 14px;

  a {
    text-decoration: underline;
  }
}
