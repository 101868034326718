.product-page-checkout {
  .btn-gradient-sample {
    max-width: 370px;
    width: 100%;
    margin: 0 auto;
  }
}

.product-page-checkout-save {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: $accent;
}

.product-page-checkout-price {
  font-family: $font-family-sans-serif;
  color: $primary-dark;
  font-weight: 800;
  font-size: 16px;

  span:first-child {
    color: $body-color;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
  }
}

.product-page-checkout-notes {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.1;

  @include media-breakpoint-up(lg) {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  img {
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  br {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
