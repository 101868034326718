.cart-quantity-dropdown {
  font-family: $font-family-sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  line-height: 1;
  border: 1px solid rgba(149, 165, 173, 0.4);

  &.hidden {
    display: none;
  }

  &:not(:first-child) {
    border-top: 0;
  }

  &:last-child {
    z-index: 10;
  }

  & + .dropdown-menu {
    border: 2px solid transparent;
    width: calc(100% - 1px);
    margin-left: 0.5px !important;
  }

  & + .dropdown-menu[data-popper-placement='bottom-start'] {
    margin-top: -5px !important;
    border-top: 0;
  }

  & + .dropdown-menu[data-popper-placement='top-start'] {
    margin-bottom: -5px !important;
    border-bottom: 0;
  }

  & + .dropdown-menu.show {
    border-color: #b8c7e1;
  }
}

.cart-quantity-dropdown-wrapper {
  position: relative;
  cursor: pointer;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 10px 50px + 12px 10px 12px;
  z-index: 10;
  border: 2px solid transparent;

  .cart-quantity-dropdown[aria-expanded='true'] > & {
    border-color: #b8c7e1;

    &::before {
      transform: rotate(180deg);
    }
  }

  .dropdown > .cart-quantity-dropdown & {
    background-color: #fbfbfc;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 46 46'%3E%3Cpath fill='%23EEF0F3' d='M0 0h46v46H0z' opacity='.75'/%3E%3Cpath fill='%23234E7F' d='m22.5 28-3.897-6.75h7.794L22.5 28Z'/%3E%3C/svg%3E");
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: calc(100% + 2px);
  }

  .dropdown > .cart-quantity-dropdown[aria-expanded='true'] > & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 46 46'%3E%3Cpath fill='%23EEF0F3' d='M0 0h46v46H0z' opacity='.75'/%3E%3Cpath fill='%23234E7F' d='m22.5 19 3.897 6.75h-7.794L22.5 19Z'/%3E%3C/svg%3E");
  }

  &:hover {
    background-color: rgba(#d9e6ff, 0.5);
  }
}

.cart-quantity-dropdown-label {
  position: relative;
  color: $primary-dark;
  font-size: 26px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
    max-width: 52px;
    text-align: left;
  }
}

.cart-quantity-dropdown-price {
  position: relative;
  font-size: 12px;
  margin-right: auto;
  padding-left: 12px;
  text-align: left;
  z-index: 1;
}

.cart-quantity-dropdown-price-value {
  display: block;
  color: $accent;
  font-weight: 800;
  margin-bottom: 2px;
}

.cart-quantity-dropdown-price-discount {
  font-size: 12px;
  text-decoration: line-through;
}

.cart-quantity-dropdown-discount {
  position: relative;
  z-index: 1;
  font-size: 12px;
}

.cart-quantity-dropdown-discount-value {
  color: $accent;
  font-weight: 800;
  margin-bottom: 2px;
  display: block;
}

.cart-quantity-dropdown-discount-save {
  font-size: 12px;
}
