.product-tabs {
  margin-top: -82px;

  .nav-link {
    color: $primary-dark;
    font-size: 18px;
    line-height: 1.27;
    padding: 12px 32px;
    border-top: 2px solid transparent;
    height: 100%;
    min-height: 82px;

    &.active,
    &.active:hover {
      border-color: $accent;
    }

    &:hover {
      color: $accent;
      border-color: transparent;
    }
  }

  .tab-content {
    background-color: $white;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.product-tabs-content {
  font-size: 16px;

  @include media-breakpoint-up(lg) {
    font-size: 20px;

    .row {
      --bs-gutter-x: 6rem;
    }
  }

  p,
  ul,
  ol {
    margin-bottom: 1.5em;
  }

  ul,
  ol {
    & ol,
    & ul {
      margin-top: 0.4em;
      margin-bottom: 0;
      padding-left: 1em;
    }

    li {
      span {
        color: $primary-dark;
      }
    }
  }

  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    > li {
      position: relative;
      padding-left: 28px;
      margin-bottom: 0.4em;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        background-color: $accent;
      }

      &::before {
        top: 0.4em;
        left: 0;
        width: 18px;
        height: 18px;
        opacity: 0.15;

        @include media-breakpoint-up(lg) {
          top: 0.4em;
        }
      }

      &::after {
        top: 0.6em;
        left: 5px;
        width: 8px;
        height: 8px;

        @include media-breakpoint-up(lg) {
          top: 13px;
        }
      }
    }

    & > li > ul {
      > li {
        &::before {
          content: none;
        }

        &::after {
          background-color: transparent;
          border: 1px solid $accent;
          top: 6px;
          width: 10px;
          height: 10px;

          @include media-breakpoint-up(lg) {
            top: 12px;
          }
        }
      }
    }
  }

  ol {
    counter-set: product-tabs-content-counter;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    > li {
      position: relative;
      counter-increment: product-tabs-content-counter;
      padding-left: 28px;
      margin-bottom: 0.4em;

      &::before {
        content: counter(product-tabs-content-counter) '.';
        position: absolute;
        top: 0;
        left: 0;
        font-size: 16px;
        font-weight: 700;
        color: $accent;

        @include media-breakpoint-up(lg) {
          top: -5px;
          font-size: 24px;
        }
      }
    }
  }
}

.product-tabs-content-title {
  font-size: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}

.product-tabs-content-subtitle {
  display: flex;
  align-items: flex-end;
  color: $primary-dark;
  font-size: 16px;
  font-weight: 900;
  padding-bottom: 14px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(#95a5ad, 0.4);
  min-height: 64px;
  width: 100%;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }

  span {
    max-width: 400px;
  }
}
