/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 15, 2022 */

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-regular-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-regular-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-black-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-blackitalic-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-blackitalic-webfont.woff')
      format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-bold-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-bolditalic-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-bolditalic-webfont.woff')
      format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-italic-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-light-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather/merriweather-lightitalic-webfont.woff2')
      format('woff2'),
    url('../fonts/Merriweather/merriweather-lightitalic-webfont.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
}

/* Webfont: LatoLatin-Black */
@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: url('../fonts/Lato/LatoLatin-Black.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Black.woff') format('woff');
}

/* Webfont: LatoLatin-BlackItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-BlackItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-BlackItalic.woff') format('woff');
}

/* Webfont: LatoLatin-Bold */
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: url('../fonts/Lato/LatoLatin-Bold.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Bold.woff') format('woff');
}

/* Webfont: LatoLatin-BoldItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-BoldItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-BoldItalic.woff') format('woff');
}

/* Webfont: LatoLatin-Hairline */
@font-face {
  font-family: 'Lato';
  font-weight: 100;
  src: url('../fonts/Lato/LatoLatin-Hairline.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Hairline.woff') format('woff');
}

/* Webfont: LatoLatin-HairlineItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 100;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-HairlineItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-HairlineItalic.woff');
}

/* Webfont: LatoLatin-Heavy */
@font-face {
  font-family: 'Lato';
  font-weight: 800;
  src: url('../fonts/Lato/LatoLatin-Heavy.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Heavy.woff') format('woff');
}

/* Webfont: LatoLatin-HeavyItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 800;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-HeavyItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-HeavyItalic.woff') format('woff');
}

/* Webfont: LatoLatin-Italic */
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-Italic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Italic.woff') format('woff');
}

/* Webfont: LatoLatin-Light */
@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url('../fonts/Lato/LatoLatin-Light.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Light.woff') format('woff');
}

/* Webfont: LatoLatin-LightItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-LightItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-LightItalic.woff') format('woff');
}

/* Webfont: LatoLatin-Medium */
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: url('../fonts/Lato/LatoLatin-Medium.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Medium.woff') format('woff');
}

/* Webfont: LatoLatin-MediumItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-MediumItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-MediumItalic.woff') format('woff');
}

/* Webfont: LatoLatin-Regular */
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url('../fonts/Lato/LatoLatin-Regular.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Regular.woff') format('woff');
}

/* Webfont: LatoLatin-Semibold */
@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: url('../fonts/Lato/LatoLatin-Semibold.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Semibold.woff') format('woff');
}
/* Webfont: LatoLatin-SemiboldItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-SemiboldItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-SemiboldItalic.woff') format('woff');
}

/* Webfont: LatoLatin-Thin */
@font-face {
  font-family: 'Lato';
  font-weight: 200;
  src: url('../fonts/Lato/LatoLatin-Thin.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-Thin.woff') format('woff');
}

/* Webfont: LatoLatin-ThinItalic */
@font-face {
  font-family: 'Lato';
  font-weight: 200;
  font-style: italic;
  src: url('../fonts/Lato/LatoLatin-ThinItalic.woff2') format('woff2'),
    url('../fonts/Lato/LatoLatin-ThinItalic.woff') format('woff');
}
