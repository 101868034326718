.footer {
  position: relative;
  background-image: linear-gradient(98.33deg, #214999 2.87%, #102f6d 100%);

  .navbar-brand {
    display: block;
    padding: 0;
    height: 40px;

    @include media-breakpoint-up(lg) {
      height: 50px;
    }

    > img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  .nav-link {
    font-size: $small-font-size;
    font-family: $font-family-sans-serif;
    color: #90b9d6;
    padding: $spacer * 0.2 0;

    svg {
      margin-top: 8px;
    }

    &:hover {
      color: $brand;
      text-decoration: underline;
    }
  }
}

.footer-nav {
  .col-lg-auto {
    margin-bottom: $spacer * 2;
  }

  @include media-breakpoint-up(lg) {
    max-width: 750px;
    margin-left: auto;

    .col-lg-auto {
      flex: 1 0 0%;
    }

    .col-lg-auto:first-child {
      max-width: 160px;
    }
  }
}

.footer-socials {
  a {
    color: $white;
    transition: $transition-color;

    &:hover {
      color: $brand;
    }
  }
}

.footer-top {
  padding-top: 64px;
  padding-bottom: 64px;

  @include media-breakpoint-up(lg) {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.footer-bottom {
  color: #90b9d6;
  font-size: $small-font-size;
  font-family: $font-family-sans-serif;
  background-image: linear-gradient(89.78deg, #102f6d 0%, #214999 100%);

  .footer-cart & {
    color: $body-color;
    background-image: linear-gradient(90.22deg, #ffffff -0.52%, #dbdee5 100%);
  }

  p {
    margin-bottom: 0;
  }

  img {
    object-fit: contain;
    object-position: left;
  }
}

.footer-title {
  color: $white;
  font-weight: bold;
}

.footer-text {
  color: $white;
  font-size: $small-font-size;
}

.footer-logo-bg {
  position: absolute;
  right: 0;
  bottom: -64px;

  @include media-breakpoint-up(lg) {
    bottom: -90px;
  }
}

.footer-button-label {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  margin-bottom: $spacer * 0.25;
}
