.why-us {
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: linear-gradient(322.87deg, #ecfaff 0%, #fff8f4 100%);

  @include media-breakpoint-up(lg) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

.why-us-title {
  max-width: 550px;

  @include media-breakpoint-down(lg) {
    font-size: 28px;
  }
}

.why-us-large-title {
  position: absolute;
  top: 140px;
  right: $spacer;
  font-family: $font-family-sans-serif;
  font-size: 70px;
  font-weight: 900;
  color: $white;
  opacity: 0.5;
  text-shadow: 0 0 30px rgba(21, 124, 199, 0.14);
  margin: 0;
  line-height: 1;

  @include media-breakpoint-up(lg) {
    top: 0;
    font-size: 135px;
  }
}

.why-us-subtitle {
  color: #6e8498;
  font-size: 26px;
  font-style: italic;
  max-width: 400px;
  line-height: 1.25;

  @include media-breakpoint-down(lg) {
    font-size: 22px;
    margin-bottom: 0;
  }
}

.why-us-item {
  color: $body-color;
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.why-us-item-icon {
  display: block;
  width: 70px;
  height: 70px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 10px;
}

.why-us-item-title {
  color: $headings-color;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
}

.why-us-item-meta {
  color: #6e8498;
  font-size: 18px;
  letter-spacing: -0.04em;
  line-height: 1.6;

  strong {
    color: #6e8498;
  }
}

.why-us-item-note {
  color: #6e8498;
  font-size: 13px;
  font-style: italic;
  line-height: 1.5;
}
