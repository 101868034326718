.product-breadcrumbs {
  font-size: 14px;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  display: none;
  align-items: center;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;

    svg {
      margin-bottom: 3px;
      margin-right: 8px;
    }
  }
}

.product-breadcrumbs-delimiter {
  margin: 0 1.1rem;

  svg {
    width: 10px;
    height: 10px;
  }
}

.product-breadcrumbs-back.btn-gradient {
  display: flex;
  align-items: center;
  font-size: 20px;
  width: calc(100% + 1rem);
  font-family: $font-family-sans-serif !important;
  font-weight: 800 !important;
  color: $accent !important;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 80px;
  text-transform: none;

  svg {
    margin-left: -1rem;
  }

  span {
    margin-left: 4px;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
