.more-less-text-hidden {
  display: none;

  .more-less-text-active & {
    display: inline;
  }
}

.more-less-text-toggle {
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 30%;
    left: calc(100% + 8px);
    transform: translateX(-50%);
    height: 8px;
    width: 10px;
    border: 0;
    background-position: center 2px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 8 5'%3E%3Cpath fill='%23F5351C' d='M3.021 3.536 6.557 0l.671.672-3.535 3.535z'/%3E%3Cpath fill='%23F5351C' d='M3.61 4.283 0 .673.672 0l3.61 3.61z'/%3E%3C/svg%3E");
    transition: transform 0.25s ease-in-out;
  }

  &.more-less-text-toggle-active {
    &::after {
      transform: translateX(-50%) rotate(180deg);
    }
  }
}
