.checkout-form {
  font-family: $font-family-sans-serif;

  .my-form {
    margin-top: 25px;
    margin-bottom: 25px;

    @include media-breakpoint-down(xxl) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .mb-form {
    margin-bottom: 25px;

    @include media-breakpoint-down(xxl) {
      margin-bottom: 20px;
    }
  }

  .mt-form {
    margin-top: 25px;

    @include media-breakpoint-down(xxl) {
      margin-top: 20px;
    }
  }

  .form-credit-card {
    position: relative;

    .form-credit-card-icon {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);

      svg {
        width: 27px;
        height: 20px;
      }
    }

    .form-control {
      padding-left: 54px;
    }
  }

  .form-cve {
    span {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      line-height: 1;
    }
  }

  hr {
    background-color: rgba(#95a5ad, 1);
    opacity: 0.35;
  }

  .iti {
    width: 100%;
  }

  .form-control {
    border-radius: 0;
    border: 1px solid rgba(35, 78, 127, 0.3);
    font-size: 20px;
    height: 50px;

    @include media-breakpoint-down(xxl) {
      height: 45px;
    }
    padding: 4px 15px;
    color: $black;

    &::placeholder {
      color: #95a5ad;
      opacity: 1;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(35, 78, 127, 0.3);
    }
  }

  .form-floating {
    .form-control {
      &::placeholder {
        opacity: 0;
      }
    }

    & > label {
      color: #95a5ad;
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 0 15px;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
    }
  }

  .input-group {
    .form-control {
      border-right: 0;
    }

    .btn {
      color: $primary-dark;
      font-size: 18px;
      font-weight: 600;
      border-radius: 0;
      border: 1px solid rgba(35, 78, 127, 0.3);
      border-left: 0;
      background-color: #eef0f3;
      padding-left: 18px;
      padding-right: 18px;
      height: 50px;

      @include media-breakpoint-down(xxl) {
        height: 45px;
      }

      &:hover {
        color: $white;
        background-color: $accent;
      }
    }
  }

  .btn-gradient-checkout {
    font-size: 18px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .btn {
    height: 50px;

    @include media-breakpoint-down(xxl) {
      height: 45px;
    }
  }

  .btn-paypal {
    background-color: $white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0;
    border: 1px solid #234e7f;

    &:hover {
      background-color: $gray-200;
    }

    svg {
      width: 70px;
      height: 20px;
    }
  }
}
