.thank-you-page {
  flex: 1;
  background-image: linear-gradient(135deg, #ecfaff 3.5%, #fff8f4 99.5%);
  padding-top: 60px;
  padding-bottom: 90px;

  @include media-breakpoint-up(lg) {
    background-image: linear-gradient(322.87deg, #f2fdff 0%, #fffaf8 100%);
    padding-top: 80px;
    padding-bottom: 200px;
  }

  .container {
    max-width: calc(800px + 2rem);
  }

  .btn-gradient-primary {
    font-size: 16px;
    font-weight: 800;
    text-transform: none;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;

    @include media-breakpoint-up(lg) {
      max-width: 336px;
    }
  }
}

.thank-you-page-above-title {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  color: $primary-dark;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }
}

.thank-you-page-title {
  font-size: 28px;
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(lg) {
    font-size: 40px;
    max-width: none;
  }
}

.thank-you-page-column-title {
  color: #293845;
  font-family: $font-family-sans-serif;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 15px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 30px;
  }
}

.thank-you-page-links {
  border-left: 1px solid rgba(204, 213, 216, 0.76);
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    padding-left: 38px;
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    max-width: 300px;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px dashed #ccd5d8;
    }
  }

  a {
    color: $primary-dark;
    font-size: 20px;
    font-weight: 300;
    text-decoration: none;
    line-height: 1.1;

    span {
      padding-left: 20px;
    }

    &:hover {
      color: $accent;
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }
}

.thank-you-page-product {
  display: flex;
  align-items: center;
  line-height: 1.1;
  margin-bottom: 15px;

  img {
    margin-right: 10px;
    width: 70px;
    height: auto;

    @include media-breakpoint-up(lg) {
      width: 100px;
    }
  }

  a {
    display: block;
    font-size: 18px;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    color: $primary-dark;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      font-family: $font-family-serif;
      font-size: 22px;
      font-weight: 700;
      max-width: 275px;
    }

    &:hover {
      color: $accent;
    }
  }

  p {
    font-size: 14px;
  }
}
