.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s ease-in-out;
  border: 0;

  svg {
    width: 1.3em;
    height: 1.3em;
    margin-right: $spacer * 0.5;
  }
}

.btn-gradient {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.25s ease-in-out;
  }

  > svg,
  > span {
    position: relative;
    z-index: 1;
  }

  &.btn-gradient-primary {
    color: $white;
    font-weight: bold;
    text-transform: uppercase;

    &::before {
      background-image: linear-gradient(99.69deg, #ee2522 9.7%, #ff4a11 95.77%);
    }

    &::after {
      opacity: 0;
      background-image: linear-gradient(99.69deg, #ff2c0f 9.7%, #ff6f06 95.77%);
    }

    &:hover::after {
      opacity: 1;
    }
  }

  &.btn-gradient-checkout {
    color: #293845;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0;
    padding: 15px 10px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }

    &::before {
      background-image: linear-gradient(90.67deg, #5ed35e 0%, #cff128 100%);
    }

    &::after {
      opacity: 0;
      background-image: linear-gradient(90.67deg, #99f899 0%, #dbff2c 100%);
    }

    &:hover::after {
      opacity: 1;
    }
  }

  &.btn-gradient-sample {
    color: #293845;
    font-size: 18px;
    font-family: $font-family-serif;
    font-weight: 300;
    text-transform: uppercase;
    border-radius: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid rgba(149, 165, 173, 0.4);

    &::before {
      background-image: linear-gradient(90.31deg, #fff8f4 0%, #ecfaff 100%);
    }

    &::after {
      opacity: 0;
      background: linear-gradient(270.31deg, #fffaf8 0%, #f2fdff 100%);
    }

    &:hover {
      color: $accent;
      border: 1px solid rgba($accent, 0.4);
    }

    &:hover::after {
      opacity: 1;
    }
  }

  &.btn-wider {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
}

.btn-login {
  color: #1f4591;
  font-size: 14px;
  border-radius: 15px;
  font-weight: 600;
  background-color: $white;
  padding: $spacer * 0.3 $spacer * 1;

  &:hover {
    color: $black;
    background-color: $brand;
  }
}

.btn-footer {
  color: #90b9d6;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;

  &:hover {
    color: $brand;
  }
}
