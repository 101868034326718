.product-gallery {
  position: relative;
  display: flex;
  height: 540px;
  margin-left: -1rem;
  margin-right: -1rem;

  @include media-breakpoint-up(lg) {
    height: 734px;
    margin-left: 0;
    margin-right: 0;
  }

  .product-gallery-thumbs {
    max-width: 100px;
    min-width: 100px;
    flex: 1;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: none;

    .swiper-slide {
      cursor: pointer;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }

    .product-gallery-thumbs-arrow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      z-index: 100;
      display: flex;
      justify-content: center;
      padding: 0;

      &:hover {
        svg {
          fill: $accent;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      svg {
        width: auto;
        height: 28px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .swiper.swiper-thumbs {
    width: 100%;
    height: calc(100% - 60px);

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(180deg, #efefed 0%, #f3f2f0 100%);

      img {
        width: 80%;
        height: auto;
      }
    }
  }

  .swiper:not(.swiper-thumbs) {
    margin-left: 10px;

    .swiper-slide {
      background-image: linear-gradient(180deg, #efefed 0%, #f3f2f0 100%);
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 60%;
        height: 60%;
        object-fit: contain;
        object-position: center;
        filter: drop-shadow(0 4px 25px rgba(76, 78, 77, 0.25));
      }
    }
  }

  .swiper-pagination {
    bottom: 12px !important;
    right: 12px !important;
    left: auto !important;
    text-align: right;

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: $accent;
    }
  }

  .product-gallery-nav {
    position: absolute;
    left: 12px;
    bottom: 12px;
    z-index: 5;

    .btn {
      width: 70px;
      height: 70px;
      border-radius: 0;
      padding: 0 !important;

      &:hover {
        opacity: 0.5;
      }

      svg {
        pointer-events: none;
        width: 100%;
        height: 100%;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.product-gallery-below {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ul {
    white-space: nowrap;
    font-size: 14px;
    margin-bottom: 0 !important;

    li::before {
      top: 3px;
    }

    li:after {
      top: 8px;
    }
  }
}
