.description {
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 16px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding-top: 140px;
    padding-bottom: 140px;
    font-size: 20px;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    @include media-breakpoint-up(md) {
      width: auto;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.description-image {
  max-height: 380px;
  margin-left: -$spacer;
  margin-right: -$spacer;
  width: calc(100% + #{$spacer * 2});
  height: 100%;
  object-fit: cover;

  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-height: 700px;
  }
}
