.industries-served {
  position: relative;
  overflow: hidden;
}

.industries-served-title {
  max-width: 450px;
}

.industries-served-subtitle {
  font-size: 20px;
}

.industries-served-image {
  max-height: 430px;
  margin-left: -$spacer;
  margin-right: -$spacer;
  width: calc(100% + #{$spacer * 2});
  height: 100%;
  object-fit: cover;
  object-position: center 10%;

  @include media-breakpoint-up(md) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-height: 100%;
  }
}
