.tpx-search-form {
  @include media-breakpoint-up(lg) {
    max-width: 650px;
  }
}

.tpx-search-form-submit {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: auto;
  }
}

.tpx-search-form-tags {
  display: flex;
  font-family: $font-family-sans-serif;

  @include media-breakpoint-up(lg) {
    align-items: center;
  }

  p {
    display: inline;
    font-size: $badge-font-size;
    padding-top: $badge-padding-y;
    padding-bottom: $badge-padding-y;
    margin-bottom: 0;
  }

  ul {
    display: inline-flex;
    align-items: center;
    flex-flow: row wrap;
    list-style-type: none;
    margin-bottom: 0;

    li {
      padding: 3px;
    }

    .badge {
      background-color: rgba($white, 0.2);

      &:hover {
        color: $white;
        background-color: $accent;
      }
    }
  }
}
