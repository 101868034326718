.industries-dropdown {
  background-color: #f2f3f6;
  padding: 0;
  left: -100px !important;
  border: 0;
  z-index: 100;

  &.show {
    display: flex;
  }

  &::before {
    left: 200px;
  }
}

.industries-dropdown-industries {
  position: relative;
  background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  height: 100%;
  max-height: 550px;
  min-width: 300px;
  max-width: 300px;
  overflow: auto;
  z-index: 100;
  padding-top: $spacer * 2;

  .simplebar-track.simplebar-vertical {
    right: auto;
    left: 4px;
    width: 9px;
    margin-top: 30px;
    z-index: -1;
  }

  .simplebar-scrollbar:before {
    background-color: #d0d4dd;
    border-radius: 5px;
    z-index: 5;
    opacity: 1 !important;
  }

  .menu-industries-item-title {
    padding-left: $spacer * 1.5;
    padding-right: $spacer;
  }

  .menu-industries-sub-items {
    padding-left: 0;
  }

  .menu-industries-sub-items li {
    margin-bottom: 0;

    a {
      font-size: 18px;
      display: block;
      padding: 3px $spacer * 2 3px $spacer * 3.6;

      &::after {
        right: $spacer * 1.5;
      }

      &.active {
        background-color: #dadde4;
      }

      &.active,
      &:hover {
        color: $accent;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='7.00734' height='1.33098' transform='matrix(-0.706861 -0.707352 0.706861 -0.707352 4.95312 5.89807)' fill='%23f5351c'/%3E%3Crect width='7.15553' height='1.33098' transform='matrix(-0.706861 0.707352 -0.706861 -0.707352 6 5.07104)' fill='%23f5351c'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}

.industries-dropdown-footer {
  position: sticky;
  bottom: 0;
  background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0 -10px 20px rgb(26 61 132 / 10%);
  border-top: 1px solid rgba(#95a5ad, 0.4);
  z-index: 100;

  .btn {
    &.btn-close {
      font-size: 16px;
      font-weight: bold;
      padding: $spacer * 0.4 $spacer * 0.8;
      color: $headings-color;
      border: 2px solid;

      &:hover {
        color: $white;
        background-color: $headings-color;
        border-color: $headings-color;
      }
    }
  }
}

.industries-dropdown-tests {
  position: relative;
  overflow: auto;
  height: 100%;
  max-height: 550px;
  min-height: 550px;
  min-width: 300px;
  max-width: 330px;
  z-index: 100;
  padding: $spacer * 2 $spacer * 2;

  .simplebar-track.simplebar-vertical {
    right: 2px;
    width: 9px;
  }

  .simplebar-scrollbar:before {
    background-color: #d0d4dd;
    border-radius: 5px;
    z-index: 5;
    opacity: 1 !important;
  }

  .menu-industries-tests-list li:not(:last-child) {
    margin-bottom: $spacer;
  }

  &.scroll {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100%;
      height: 30px;
      z-index: 5;
      padding-bottom: 10px;
      padding-top: 10px;
      background-color: #f2f3f6;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px 10px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 8'%3E%3Cpath fill='%23C4C4C4' d='m5 0 4.33 7.5H.67L5 0Z'/%3E%3C/svg%3E");
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100%;
      height: 30px;
      z-index: 5;
      padding-bottom: 10px;
      padding-top: 10px;
      background-color: #f2f3f6;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8L0.669872 0.5L9.33013 0.5L5 8Z' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
    }
  }

  a {
    &:hover {
      color: $accent;
    }
  }
}
