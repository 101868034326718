.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

.fw-extrabold {
  font-weight: 800;
}

.text-accent {
  color: $accent;
}

.text-primary-dark {
  color: $primary-dark;
}
