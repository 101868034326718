.practice-tests {
  position: relative;
  overflow: hidden;
  color: $white;
  background-color: $primary;
}

.practice-tests-title {
  color: $white;
  max-width: 620px;
}

.practice-tests-text {
  font-size: 20px;
}

.practice-tests-image {
  max-height: 380px;
  margin-left: -$spacer;
  margin-right: -$spacer;
  width: calc(100% + #{$spacer * 2});
  height: 100%;
  object-fit: cover;

  @include media-breakpoint-up(md) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-height: 1100px;
  }
}
