.review-and-stats {
  padding-top: 60px;
  padding-bottom: 60px;

  @include media-breakpoint-up(lg) {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .trustpilot-top,
  .trustpilot-bottom {
    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
  }
}

.review-and-stats-title {
  max-width: 600px;
}

.review-and-stats-text {
  color: #6e8498;
}
