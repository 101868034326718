.product-page-discount {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  line-height: 1.4;
  padding: 10px 15px;
  border-radius: 15px;
  border: 1px solid rgba(#e93030, 0.05);
  background-image: linear-gradient(
    90.45deg,
    rgba(#ff7223, 0.05) 0%,
    rgba(#ffde67, 0.05) 100%
  );

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    text-align: left;
  }

  .btn-copy {
    font-size: 14px;
    text-transform: uppercase;
    color: #1f4591;
    background-color: $white;
    border: 1px solid rgba(149, 165, 173, 0.4);
    min-width: 190px;
    border-radius: 0;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 0;
      min-width: 120px;
    }

    &:hover {
      background-color: $accent;
      border-color: $accent;
      color: $white;
    }
  }
}

.product-page-discount-text {
  strong {
    color: $accent;
    font-weight: 800;
  }

  span {
    color: $black;
  }
}

.product-page-discount-timer {
  color: $accent;

  strong {
    color: currentColor;
    font-weight: 800;
  }
}
