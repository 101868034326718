.promo-cta {
  position: relative;
  overflow: hidden;

  .btn {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }
}

.promo-cta-caption {
  padding-top: 60px;
  padding-bottom: 60px;
}

.promo-cta-title {
  color: $white;
  max-width: 310px;

  @include media-breakpoint-down(lg) {
    font-size: 30px;
  }

  @include media-breakpoint-up(lg) {
    max-width: unset;
  }
}

.promo-cta-subtitle {
  color: $white;
  font-size: 28px;
  display: block;
  margin-bottom: 0;
  line-height: 1.2;
}

.promo-cta-large-title {
  position: absolute;
  top: -$spacer;
  left: $spacer;
  color: $white;
  margin-bottom: 0;
  font-size: 78px;
  font-family: $font-family-sans-serif;
  font-weight: 900;
  text-shadow: 0 0 44.7312px rgba(10, 25, 36, 0.11);
  opacity: 0.15;

  @include media-breakpoint-up(lg) {
    top: 50%;
    transform: translateY(-50%);
    font-size: 150px;
  }
}

.promo-cta-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include media-breakpoint-down(sm) {
    position: absolute;
    top: -53%;
    left: -89%;
    width: 299%;
    height: 300%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 30%;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background-image: linear-gradient(
      83.02deg,
      #7267ea -19.23%,
      rgba(40, 182, 238, 0.32) 58.95%
    );
    opacity: 0.2;
  }

  &::after {
    background-image: linear-gradient(
      90deg,
      #15212f 0%,
      rgba(29, 37, 47, 0) 100%
    );
    opacity: 0.8;
  }
}
