.fslightbox-toolbar {
  .fslightbox-toolbar-button:first-child {
    display: none !important;
  }

  .fslightbox-toolbar-button svg {
    width: 36px;
    height: 36px;
  }
}
