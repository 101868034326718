.blogs {
  .section {
    padding-top: 0 !important;
  }

  .swiper {
    overflow: hidden !important;
    padding-bottom: $spacer * 2;
  }

  .swiper-wrapper {
    align-items: stretch !important;
  }

  .swiper-slide {
    height: auto !important;
  }
}
