.landing-hero {
  position: relative;
  overflow: hidden;
  background-color: #214999;

  @include media-breakpoint-up(wide) {
    .container {
      position: relative;
      max-width: 1480px;
    }
  }

  .highlight {
    color: $white;
  }
}

.landing-hero-wrapper {
  margin-right: -$spacer;
}

.landing-hero-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 120px;
  padding-bottom: 32px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    padding-top: 200px;
    padding-bottom: 90px;
    height: 100vh;
    max-height: 850px;
  }
}

.landing-hero-caption-inner {
  position: relative;
  z-index: 5;
}

.landing-hero-form {
  position: relative;
  z-index: 5;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.landing-hero-title {
  color: $white;
  font-size: 36px;
  font-weight: bold;
  max-width: 350px;

  @include media-breakpoint-up(lg) {
    font-size: 52px;
    max-width: 100%;
  }
}

.landing-hero-text {
  color: $white;
  font-size: 20px;
  max-width: 340px;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
    max-width: 650px;
  }
}

.landing-hero-image-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;

  @include media-breakpoint-down(lg) {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    margin-left: -$spacer;
    margin-right: -$spacer;
    width: calc(100% + (#{$spacer} * 4));
    margin-top: -220px;
    margin-bottom: -66px;
  }

  @include media-breakpoint-up(lg) {
    top: 0;
    transform: none;
    width: 56%;
    height: 100%;
    margin-top: 0;
  }

  @include media-breakpoint-up(wide) {
    width: 50%;
    right: $spacer;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background-image: linear-gradient(
      0deg,
      rgba(25, 31, 43, 0.54) 0%,
      rgba(0, 24, 70, 0) 61.37%
    );
  }

  &::after {
    background-image: linear-gradient(
      180deg,
      rgba(25, 31, 43, 0.54) 0%,
      rgba(0, 24, 70, 0) 100%
    );
  }

  @include media-breakpoint-up(wide) {
    &::before,
    &::after {
      content: none;
    }
  }
}

.landing-hero-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include media-breakpoint-down(lg) {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    height: 450px;
  }

  @include media-breakpoint-up(wide) {
    height: 65%;
    top: 55%;
    transform: translateY(-50%);
  }
}

.landing-hero-play {
  position: absolute;
  top: 65%;
  left: calc(50% - 1rem);
  transform: translate(-50%, -50%);
  z-index: 100;

  @include media-breakpoint-up(lg) {
    top: 50%;
    margin-left: $spacer * 2;
  }

  @include media-breakpoint-up(wide) {
    top: 55%;
    margin-left: 0;
  }
}
