.accordion-item {
  margin-bottom: 0.2rem;
}

.accordion-body {
  line-height: 1.88;

  @include media-breakpoint-down(lg) {
    line-height: 1.5;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .accordion-dark & {
    background-color: $primary;

    a {
      color: #ffaa9f;
    }
  }

  .accordion-dark &.accordion-body-white {
    background-color: #fafafa;
    padding: 36px 0;
  }

  .accordion-payment & {
    font-family: $font-family-sans-serif;
    background-color: #fbfbfc;
    padding: 20px 15px 40px;
  }
}

.accordion-button {
  --icon-gradient-start: #ee2522;
  --icon-gradient-stop: #ff4a11;

  background-image: linear-gradient(90.99deg, #fffaf8 0%, #f2fdff 100%);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  box-shadow: 0 1px 4px rgba(50, 66, 69, 0.2);

  .accordion-product & {
    font-size: 14px;
    text-transform: none;
    padding: 1.15em 1.2em;
    background-color: #1e438d;
  }

  @include media-breakpoint-down(lg) {
    font-size: 16px;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  &:not(.collapsed) {
    box-shadow: 0 1px 4px rgba(50, 66, 69, 0.2);
  }

  &::after {
    background-position: center;
  }

  &:focus {
    box-shadow: 0 1px 4px rgba(50, 66, 69, 0.2);
  }

  svg {
    width: 30px;
    height: 30px;
  }

  &.collapsed {
    --icon-gradient-start: $primary-dark;
    --icon-gradient-stop: #00b2ff;
  }

  .accordion-dark & {
    $accordion-icon-color: $white;
    $accordion-icon-active-color: #ffaa9f;

    --icon-gradient-start: #ffaa9f;
    --icon-gradient-stop: #ffaa9f;

    color: #ffaa9f;
    background-image: none;
    background-color: #1a3d84;

    &::after {
      width: 30px;
      height: 30px;
      background-size: 30px 30px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 31 3'%3E%3Cpath stroke='%23ffaa9f' d='M.589.5h29.978'/%3E%3C/svg%3E");
    }

    &.collapsed {
      --icon-gradient-start: #fff;
      --icon-gradient-stop: #fff;

      color: $white;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 31 30'%3E%3Cpath stroke='%23fff' d='M.589 15.5h29.978M16.077 0v30'/%3E%3C/svg%3E");
      }
    }
  }

  .accordion-payment & {
    font-size: 16px;
    font-family: $font-family-sans-serif;
    font-weight: 800;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: none;
    border: 1px solid rgba(149, 165, 173, 0.4);
    color: $primary-dark;
    height: 67px;
    text-transform: none;

    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: space-between;
      padding-left: 54px;
      padding-right: 1rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      text-transform: uppercase;
      padding-left: 72px;
    }

    &:hover::after {
      border-radius: 50%;
      animation: radio-payment-hovered 0.2s ease-in-out forwards;
    }

    &[aria-expanded='true']::after {
      border-radius: 50%;
      animation: radio-payment-clicked 0.4s ease-in-out;
    }

    &::after {
      position: absolute;
      right: auto;
      left: 26px;
      width: 25px;
      height: 25px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.5' cy='12.5' r='11.5' stroke='%23214999' stroke-width='2'/%3E%3C/svg%3E%0A");

      @include media-breakpoint-down(lg) {
        left: 1rem;
      }
    }

    &[aria-expanded='true'] {
      color: $primary-dark;
    }

    &[aria-expanded='true']::after {
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.5' cy='12.5' r='11.5' stroke='%23E93030' stroke-width='2'/%3E%3Ccircle cx='12.5' cy='12.5' r='6.5' fill='%23E93030'/%3E%3C/svg%3E%0A");
    }

    img {
      width: 145px;
      height: 22px;
      margin-left: 0;

      @include media-breakpoint-up(lg) {
        width: 196px;
        height: 31px;
        margin-left: 24px;
      }
    }

    svg {
      width: 70px;
      height: 20px;
    }
  }
}

@keyframes radio-payment-clicked {
  0% {
    box-shadow: 0 0 0 0 rgb(233 48 48 / 15%);
  }

  50% {
    box-shadow: 0 0 0 10px rgb(233 48 48 / 15%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(233 48 48 / 15%);
  }
}

@keyframes radio-payment-hovered {
  0% {
    box-shadow: 0 0 0 0 rgba(#214999, 0.15);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(#214999, 0.15);
    background-color: rgba(#214999, 0.15);
  }
}
